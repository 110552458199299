/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable camelcase */

import { appDebug } from 'configs/env';
import { features, oids } from 'configs';
import i18n from 'i18n';
import dictionary from './DocumentNames.json';
import { namesLowerCase, vaccineManufacturers } from './Constants';
import storage_keys from './storage_keys';

export const translate = (text) => {
  switch (text) {
    case 'Blood pressure systolic and diastolic':
      return 'Pressão Arterial';
    case 'Body weight':
      return 'Peso';
    case 'Body height':
      return 'Altura';
    case 'food':
      return 'Comida';
    case 'medication':
      return 'Medicação';
    case 'environment':
      return 'Ambiente';
    case 'biologic':
      return 'Biológico';
    case 'low':
      return 'Baixo';
    case 'unable-to-assess':
      return 'Não Informado';
    case 'high':
      return 'Alto';
    case 'active':
      return 'Ativo';
    case 'current':
      return 'atual';
    case 'inactive':
      return 'Inativo';
    case 'resolved':
      return 'Resolvido';
    case 'preparation':
      return 'Em preparação';
    case 'in-progress':
      return 'Em progresso';
    case 'not-done':
      return 'Não Concluído';
    case 'on-hold':
      return 'Em espera';
    case 'stopped':
      return 'Interrompido';
    case 'completed':
      return 'Concluído';
    case 'entered-in-error':
      return 'Inserido com Erro';
    case 'unknown':
      return 'Desconhecido';
    case 'all':
      return 'Todos';
    case 'fulfilled':
      return 'Finalizados';
    case 'notFulfilled':
      return 'Não Finalizados';
    case 'welcomed':
      return 'Acolhidos';
    case 'late':
      return 'Atrasados';
    case 'pending':
      return 'Pendente';
    case 'booked':
      return 'Agendado';
    case 'attended':
      return 'Atendido/Internado';
    case 'cancelled':
      return 'Negado/Cancelado';
    case 'absence':
      return 'Falta';
    case 'excluded':
      return 'Excluído';
    case 'returned-to-requester':
      return 'Devolvido para o solicitante';
    default:
      return text;
  }
};

export function translateGender(gender) {
  switch (gender?.toLowerCase()) {
    case 'male':
      return 'Masculino';
    case 'female':
      return 'Feminino';
    case 'm':
      return 'Masculino';
    case 'f':
      return 'Feminino';
    default:
      return gender;
  }
}

export function logDebug() {
  try {
    if (appDebug) console.log.apply(null, arguments); //eslint-disable-line
  } catch (e) {
    if (appDebug) console.log('DEB: ', e); //eslint-disable-line
  }
}

export function removeTimeOffset(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function getFromPromiseAllSettled(mappingGroup) {
  mappingGroup = mappingGroup.filter(
    (mapItem) => mapItem.status === 'fulfilled',
  );
  mappingGroup = mappingGroup.map((mapItem) => mapItem.value);
  return mappingGroup;
}

export function removeElementsWithoutIDs(mappingGroup) {
  mappingGroup = mappingGroup?.filter(
    (mapItem) => !!mapItem?.id || !!mapItem?.id?.split('-')[1],
  );
  return mappingGroup;
}

export const zip = function (arrays) {
  return arrays[0].map((_, i) => arrays.map((array) => array[i]));
};

export const code_to_gender = function cg(code) {
  const gender_codes = {
    M: 'Masculino',
    F: 'Feminino',
    I: 'Ignorado',
  };
  return gender_codes[code];
};

export const checkWhiteSpace = function (str) {
  if (/\s/.test(str)) {
    // It has any kind of whitespace
    return true;
  }
  return false;
};

export const formatPhoneNumber = function (text) {
  let temp = text;
  if (temp.length < 14) {
    temp = text.replace(/\D/g, '');
    temp = temp.replace(/^(\d{2})(\d+)/, '($1)$2');
    temp = temp.replace(/^\((\d{2})\)(\d{4})(\d+)/, '($1)$2-$3');
  }
  if (temp.length === 14) {
    temp = text.replace(/\D/g, '');
    temp = temp.replace(/^(\d{2})(\d+)/, '($1)$2');
    temp = temp.replace(/^\((\d{2})\)(\d{5})(\d{4})/, '($1)$2-$3');
  }
  return temp.replace(/\s/g, ''); // return value to print and value to send to api
};

export const chunk = function (myArray, chunkSize) {
  return Array(Math.ceil(myArray.length / chunkSize))
    .fill()
    .map((_, i) => myArray.slice(i * chunkSize, i * chunkSize + chunkSize));
};

export const unformatCPFCNS = function (data) {
  data = data.replace(/\./g, '');
  data = data.replace(/ /g, '');
  data = data.replace('-', '');
  return data;
};

// Função responsável por "aplainar" todos os labels de nível 4, fazendo com
// que todos os níveis maiores do que 4 se transformem em um campo de nível 4.
export const flatten = function (data) {
  const result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) result[prop] = cur;
    else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i += 1) recurse(cur[i], `${prop}[${i}]`);
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (const p in cur) {
        if (p) {
          if (
            p === 'terminology_name'
            || p === 'defining_code'
            || p === 'value'
          ) {
            if (!result[prop]) result[prop] = {};
            isEmpty = false;
            result[prop][p] = cur[p];
          } else {
            isEmpty = false;
            recurse(cur[p], prop ? `${prop}__${p}` : `__${p}`);
          }
        }
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
};

export const verifyEmptyTag = function (received_obj) {
  // Função responsável por verificar se um objeto é do tipo {label: value}
  // O que significa que o mesmo já pode ser renderizado como um campo da dados
  // (pela função createDataField) e não como um título (create_level_X)

  let obj = received_obj;
  let key = '';
  if (Object.keys(obj).length === 1) {
    // eslint-disable-next-line prefer-destructuring
    key = Object.keys(obj)[0];

    if (typeof obj[key] === 'string') return obj;
    obj = verifyEmptyTag(obj[key]);
    // eslint-disable-next-line prefer-destructuring
    if (Array.isArray(obj)) obj = obj[0];
  } else return obj;
  return [obj, key];
};

export const capitalize_first_letters = function (txt) {
  if (txt) {
    txt = txt.toLowerCase();
    txt = txt.slice(0, 1).toUpperCase() + txt.slice(1, txt.length);
    let i = 2;
    let index = 0;
    while (txt.indexOf(' ', i) !== -1) {
      index = txt.indexOf(' ', i);
      txt = txt.slice(0, index + 1)
        + txt.slice(index + 1, index + 2).toUpperCase()
        + txt.slice(index + 2, txt.length);

      i = index + 1;
    }
    return txt;
  }
  return 'Não Informado';
};

export const year_to_interval = function (year) {
  return [
    year.toString().concat('0101000000'),
    year.toString().concat('1231235959'),
  ];
};

export const int_to_month = function (i) {
  if (i < 0 || i >= 12) return 'N/A';

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  return months[i];
};

export const parse_reports = function (reports) {
  let result = [];
  result = [
    {
      title: 'Dezembro',
      data: [],
    },
    {
      title: 'Novembro',
      data: [],
    },
    {
      title: 'Outubro',
      data: [],
    },
    {
      title: 'Setembro',
      data: [],
    },
    {
      title: 'Agosto',
      data: [],
    },
    {
      title: 'Julho',
      data: [],
    },
    {
      title: 'Junho',
      data: [],
    },
    {
      title: 'Maio',
      data: [],
    },
    {
      title: 'Abril',
      data: [],
    },
    {
      title: 'Março',
      data: [],
    },
    {
      title: 'Fevereiro',
      data: [],
    },
    {
      title: 'Janeiro',
      data: [],
    },
  ];

  if (!reports.length) return [false];

  for (const report of reports) {
    const month = report.serviceStart_time.substring(4, 6);
    result[12 - parseInt(month, 10)].data.push(report);
  }

  return result;
};

export const javascriptDigit = function (char, base) {
  const retorno = parseInt(char, base);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(retorno)) return -1;
  return retorno;
};

export const toCharArray = function (s) {
  return s.split('').join('');
};

export const somaPonderada = function (s) {
  const cs = toCharArray(s);
  let soma = 0;
  for (let i = 0; i < cs.length; i += 1) soma += javascriptDigit(cs[i], 10) * (15 - i);

  return soma;
};

export const CNSisValid = function (s) {
  try {
    s = s.toString();
    s = s.replace(/\D/g, '');
    const condFormat = s.match('[1-2]\\d{10}00[0-1]\\d') || s.match('[7-9]\\d{14}');
    if (condFormat) return somaPonderada(s) % 11 === 0;

    return false;
  } catch (err) {
    return false;
  }
};

export const cpfValidator = function (cpfGot) {
  if (!features.cpfValidator) return true;
  let Soma;
  let Resto;
  Soma = 0;
  let cpf = '';
  if (cpfGot) cpf = cpfGot.replace(/\D/g, '');
  if (
    cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999'
  ) return false;

  const id_array = cpf.split('');
  if (id_array.length !== 11) return false;

  for (let i = 1; i <= 9; i += 1) Soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(cpf.substring(9, 10), 10)) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i += 1) Soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(cpf.substring(10, 11), 10)) return false;

  return true;
};

export const verifyCPF = function (cpf) {
  if (!cpfValidator(cpf)) {
    // logDebug('CPF Inválido', 'Por favor, verifique o CPF!!!');
    return false;
  }
  return true;
};

export const cpfFormat = function (cpf) {
  let text = cpf;
  text = text.replace(/\D/g, '');
  text = text.replace(/^(\d{3})(\d+)/, '$1.$2');
  text = text.replace(/^(\d{3}).(\d{3})(\d+)/, '$1.$2.$3');
  text = text.replace(/^(\d{3}).(\d{3}).(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
  if (text.length >= 14) verifyCPF(text);
  return text;
};

export const verifyPhone = function (phone) {
  if (/^(\(.*\)[0-9]{5}-[0-9]{4})$/.test(phone) === false) return false;
  return true;
}; // (21) 99999-9999

export const verifyEmail = function (email) {
  if (/^(\b[\w.-]+@[\w.-]+\.\w{1,4}\b)$/.test(email) === false) return false;
  return true;
};

export async function storeData(name, data) {
  let throwError = false;
  try {
    if (storage_keys[name]) sessionStorage.setItem(name, data);
    else {
      const items = Object.keys(storage_keys).filter((key) => key.startsWith(name.split('-')[0]));
      if (items.length === 0) throwError = true;
      else sessionStorage.setItem(name, data);
    }
  } catch (err) {
    logDebug('Error in storage_data');
    logDebug(`name: ${name}`);
    logDebug(`data: ${data}`);
  }
  if (throwError) {
    throw new Error(
      `É necessário inserir a key e sua descrição dentro de /utils/storage_keys\nname: ${name}\ndata: ${data}`,
    );
  }
}

export async function retrieveData(name) {
  try {
    return sessionStorage.getItem(name);
  } catch (err) {
    logDebug(`Erro: retrieveData: ${err}`);
  }
  return null;
}

export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) return false;

  return typeof obj[Symbol.iterator] === 'function';
}

export async function removeData(name) {
  try {
    sessionStorage.removeItem(name);
  } catch (err) {
    logDebug(`Erro: removeData: ${err}`);
  }
}

export async function clearAllData() {
  try {
    sessionStorage.clear();
  } catch (err) {
    logDebug(`Erro: clearAllData: ${err}`);
  }
}

export async function setRoles(response) {
  // logDebug('response :>> ', response);
  let isProfessional = false;
  if (response && response !== 'network_error') {
    const roles = response.Resources[0].roles[0].value;
    isProfessional = roles.indexOf('RL_PROFISSIONAL') > -1;
  }
  await storeData('isProfessional', isProfessional);
}

export function findCommonElements(arr1, arr2) {
  try {
    const diffNull = (item) => item !== null;
    const common = (item) => arr2.includes(item);
    return arr1.filter(common).filter(diffNull);
  } catch (err) {
    return [];
  }
}

export function removeXMLInvalidChars(string) {
  string = string.replace(/[\n\r]/g, '');
  return string;
}

export function displayFullNameDoc(name) {
  name = name.toLowerCase();
  let newName = '';
  try {
    // eslint-disable-next-line prefer-destructuring
    newName = dictionary[name][0];
  } catch (err) {
    newName = '';
    // logDebug('err', err);
  }
  return newName;
}

export function extractDocType(doc) {
  // Extrai nome do tipo de documento de dentro do xml
  let name;
  if (typeof doc === 'string') {
    name = doc.split('template_id="');
    if (name.length > 1) {
      [, name] = name;
      name = name.split('"');
      [name] = name;
      return name;
    }
  }

  return null;
}

export const modifyXmlValues = (xmlString, keyPath, callback) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
  function traverseNodes(node, keyPath, currentPathIndex = 0) {
    const nodesToRemove = [];

    node.childNodes.forEach((child) => {
      if (child.nodeName === keyPath[currentPathIndex] && child.nodeType === 1) {
        if (currentPathIndex === keyPath.length - 1) {
          const result = callback(child);
          if (result === null) nodesToRemove.push(child);
        } else traverseNodes(child, keyPath, currentPathIndex + 1);
      }
    });

    nodesToRemove.forEach((nodeToRemove) => nodeToRemove.parentNode.removeChild(nodeToRemove));
  }

  traverseNodes(xmlDoc.documentElement, Array.isArray(keyPath) ? keyPath : [keyPath]);

  const serializer = new XMLSerializer();
  return serializer.serializeToString(xmlDoc);
};
export const formatXmlData = (responseXml) => {
  const is_atendimento = responseXml.includes('ot:Prescrição_no_atendimento');
  const getReplaced = (str) => str
    .replace('ot:', is_atendimento ? 'ot:' : '')
    .replace('no_atendimento', is_atendimento ? 'no_atendimento' : 'da_alta');

  responseXml = modifyXmlValues(responseXml, [
    is_atendimento ? 'ot:Prescrição_no_atendimento' : 'Prescrição_da_alta',
    getReplaced('ot:Linha_de_Medicação'),
    getReplaced('ot:data'),
    getReplaced('ot:Lista_de_medicamentos_no_atendimento__openBrkt_estruturada_closeBrkt_'),
    getReplaced('ot:Medicamento'),
  ], (oldValue) => {
    const codeStringNode = oldValue.getElementsByTagName(getReplaced('ot:code_string'))[0];
    const medicationValueNode = oldValue.getElementsByTagName(getReplaced('ot:value'))[1];
    const medicationDescriptionNode = medicationValueNode?.getElementsByTagName(getReplaced('ot:value'))[0];

    if (medicationDescriptionNode && codeStringNode) {
      codeStringNode.parentNode.removeChild(codeStringNode);
      return oldValue;
    }
    if (codeStringNode && !medicationDescriptionNode) return oldValue;
    if (!codeStringNode && !medicationDescriptionNode) return null;

    return oldValue;
  });

  responseXml = modifyXmlValues(responseXml, [
    is_atendimento ? 'ot:Prescrição_no_atendimento' : 'Prescrição_da_alta',
    getReplaced('ot:Linha_de_Medicação'),
    getReplaced('ot:data'),
    getReplaced('ot:Lista_de_medicamentos_no_atendimento__openBrkt_estruturada_closeBrkt_'),
    getReplaced('ot:Dose_estruturada'),
  ], (oldValue) => {
    const dose_node = oldValue.getElementsByTagName(getReplaced('ot:Dose'))[0];
    const qtd_unidade_medida_node = dose_node.getElementsByTagName(getReplaced('ot:Quantidade_da_unidade_de_medida'))[0];
    const value_node = qtd_unidade_medida_node.getElementsByTagName(getReplaced('ot:value'))[1];
    const magnitude_node = value_node.getElementsByTagName('oe:magnitude')[0];
    const original_value = magnitude_node.innerHTML;

    magnitude_node.innerHTML = String(parseFloat(original_value)).replace('.', ',');

    return oldValue;
  });

  responseXml = modifyXmlValues(responseXml, [
    is_atendimento ? 'ot:Prescrição_no_atendimento' : 'Prescrição_da_alta',
    getReplaced('ot:Linha_de_Medicação'),
    getReplaced('ot:data'),
    getReplaced('ot:Lista_de_medicamentos_no_atendimento__openBrkt_estruturada_closeBrkt_'),
    getReplaced('ot:Dose_estruturada'),
  ], (oldValue) => {
    let duracao_uso_medicamento_node;
  
    if (is_atendimento) {
      const dose_node = oldValue.getElementsByTagName(getReplaced('ot:Dose'))[0];
      duracao_uso_medicamento_node = dose_node.getElementsByTagName(getReplaced('ot:Duração_de_uso_do_medicamento'))[0];
    } else {
      duracao_uso_medicamento_node = oldValue.getElementsByTagName(getReplaced('ot:Duração_de_uso_do_medicamento'))[0];
    }
  
    const value_node = duracao_uso_medicamento_node.getElementsByTagName(getReplaced('ot:value'))[1];
    const inner_value_node = value_node.getElementsByTagName(getReplaced('ot:value'))[0];
  
    const original_value = inner_value_node.innerHTML.trim();
  
    let new_value;
    if (original_value === "P1000M") {
      new_value = "Uso contínuo";
    } else if (original_value === "P2000M") {
      new_value = "Indeterminado";
    } else {
      const match = original_value.match(/^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?$/);
  
      if (match) {
        const years = parseInt(match[1] || "0", 10);
        const months = parseInt(match[2] || "0", 10);
        const weeks = parseInt(match[3] || "0", 10);
        const days = parseInt(match[4] || "0", 10);
  
        const total_days = years * 365 + months * 30 + weeks * 7 + days;
  
        if (years > 0) {
          new_value = `${years} ano${years > 1 ? 's' : ''}`;
          if (months > 0 || weeks > 0 || days > 0) {
            new_value += ` e ${months > 0 ? months + ' mês' + (months > 1 ? 'es' : '') : ''}`;
            if (weeks > 0 || days > 0) {
              new_value += `${weeks > 0 ? ' e ' + weeks + ' semana' + (weeks > 1 ? 's' : '') : ''}`;
              if (days > 0) {
                new_value += ` e ${days} dia${days > 1 ? 's' : ''}`;
              }
            }
          }
        } else if (months > 0) {
          new_value = `${months} mês${months > 1 ? 'es' : ''}`;
          if (weeks > 0 || days > 0) {
            new_value += `${weeks > 0 ? ' e ' + weeks + ' semana' + (weeks > 1 ? 's' : '') : ''}`;
            if (days > 0) {
              new_value += ` e ${days} dia${days > 1 ? 's' : ''}`;
            }
          }
        } else if (weeks > 0) {
          new_value = `${weeks} semana${weeks > 1 ? 's' : ''}`;
          if (days > 0) {
            new_value += ` e ${days} dia${days > 1 ? 's' : ''}`;
          }
        } else {
          new_value = `${total_days} dia${total_days > 1 ? 's' : ''}`;
        }
      } else {
        new_value = original_value;
      }
    }
  
    inner_value_node.innerHTML = new_value;
  
    return oldValue;
  });

  return responseXml;
};
export async function fetchWithTimeout(url, options, timeout = 15000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout)),
  ]);
}

function removeFirstLetter(name) {
  let newName = name.split('');
  newName.shift();
  newName = newName.join('');
  return newName;
}

function formatOneName(nameArg, nameLowerCase) {
  const nameStr = nameArg;
  const names = nameStr.split(' ');
  let newName = '';
  let name = '';
  for (let i = 0; i < names.length; i += 1) {
    names[i] = names[i].replace(' ', '');
    if (names[i] === nameLowerCase) {
      name = names[i];
      name = name.split('');
      name[0] = name[0].toLowerCase();
      name = name.join('');
      name = ` ${name}`;
    } else name = ` ${names[i]}`;

    newName = newName.concat(name);
    if (newName[0] === ' ') newName = removeFirstLetter(newName);
  }
  return newName;
}

export function formatName(name) {
  let newName = name.toLowerCase();
  newName = capitalize_first_letters(newName);
  for (let i = 0; i < namesLowerCase.length; i += 1) newName = formatOneName(newName, namesLowerCase[i]);

  return newName;
}

const BackToLogin = async () => {
  // sessionStorage.clear();
  // await storeData('userLogged', false);
  // document.location.reload();
};
export { BackToLogin };

export const verifyUnauthorized = async (error) => {
  if (error === 'unauthorized') BackToLogin();
};

export const refreshScreen = () => {
  document.location.reload();
};

/**
 * ASCII to Unicode (decode Base64 to original data)
 * @param {string} b64
 * @return {string}
 */
export const atou = (b64) => decodeURIComponent(escape(atob(b64)));

/**
 * Unicode to ASCII (encode data to Base64)
 * @param {string} data
 * @return {string}
 */
export const utoa = (data) => btoa(unescape(encodeURIComponent(data)));

export const removeAccent = (str) => {
  const map = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  for (const pattern in map) str = str.replace(new RegExp(map[pattern], 'g'), pattern);

  return str;
};

export const validatePostalCode = (str) => {
  if (str.match(/[\d]{4,8}/g)) return true;
  return false;
};

export const logoutFromPortal = async () => {
  let gov = await retrieveData('govBR');
  gov = gov === 'true';
  await clearAllData();
  await removeData('cnesToUse');
  await storeData('userLogged', false);
  await storeData('isProfessional', false);
  await storeData('govBR', false);
  if (gov) {
    window.location.href = `https://sso.acesso.gov.br/logout?post_logout_redirect_uri=${
      window.location.href.split('#')[0]
    }`;
  }
};

export function transformToAssocArray(prmstr) {
  let params = [];
  if (prmstr) {
    const prmarr = prmstr.split('&');
    // eslint-disable-next-line array-callback-return
    prmarr.map((prm) => {
      params = [...params, prm];
    });
  }
  return params;
}

export function getSearchParameters() {
  const prmstr = window.location.href.split('?')[1];
  return prmstr !== null && prmstr !== '' ? transformToAssocArray(prmstr) : [];
}

export const eliminateLeastSignificantNumber = (number) => Math.floor(number / 10);

export function groupBy(indexJsonArray, propertyToGroup) {
  return indexJsonArray.reduce((rv, x) => {
    (rv[x[propertyToGroup]] = rv[x[propertyToGroup]] || []).push(x);
    return rv;
  }, {});
}

export const addManufacturers = (vaccines) => {
  let vaccinesArr = [...vaccineManufacturers];
  vaccinesArr = vaccinesArr.map((vaccineMan) => {
    const temp = vaccines.filter(
      (vaccine) => vaccine.code === vaccineMan.code,
    )[0];
    return { ...vaccineMan, name: temp.display };
  });
  return vaccinesArr;
};

export function isBase64(str) {
  if (str === '' || str.trim() === '') return false;
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function extractIdentifiers(patientMhd) {
  const identifiers = {};
  patientMhd.identifier.forEach((item) => {
    if (item.system?.includes(oids.cns) && item.use === 'official') identifiers.cns = item.value;
  });
  return identifiers;
}

export function checkRegisterType(register, identifier = null) {
  let value = register;
  if (identifier) {
    const aux_value = identifier.value;
    value = aux_value;
  }
  if (verifyCPF(value)) {
    return i18n.t('ProfilePage-userProfile-nationalIdentifierField', {
      ns: 'Profile',
    });
  }
  if (CNSisValid(value)) {
    return i18n.t('ProfilePage-userProfile-healthNationalIdentifierField', {
      ns: 'Profile',
    });
  }
  if (identifier && identifier?.type?.text) return identifier.type.text;
  if (identifier && identifier?.assigner) return identifier.assigner.display;

  return 'Identificador';
}

export const isEmptyObject = (object) => {
  try {
    return JSON.stringify(object) === JSON.stringify({});
  } catch (err) {
    logDebug('isEmptyObject', err);
  }
  return false;
};
