import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LoadMoreScroll, NoReports, Loading,
} from 'components';
import MHDRequests from 'services/MHDRequests';
import { verifyCPF } from 'utils/AuxiliarFunctions';
import { oids } from 'utils/Constants';
import { formatExamTimeline } from 'utils/fhirParser';
import { useTranslation } from 'react-i18next';
import Timeline from './Timeline/Timeline';
import './TimelineDrawer.css';

export function TimelineExamViwer({
  patientId, organizationId, selectReport, subjectId, purposeOfUse,
  subjectToken,
  subjectRole,
  examPdfCallback,
}) {
  const [loading, setLoading] = useState(true);
  const [timelineLength, setTimelineLength] = useState(5);
  const [isReportsUnavailable, setIsReportsUnavaiable] = useState(false);
  const { t } = useTranslation('PatientSummary');

  const [allDocuments, setAllDocuments] = useState([]);
  const [documentsShown, setDocumentsShown] = useState([]);
  const [timelineFilter, setTimelineFilter] = useState({
    display: t('TimelineDrawer-patientSummary-timeline-chooseFilter-button'),
    types: [],
  });

  const getTimelineType = async ({ types }) => {
    if (!Array.isArray(types)) return;
    const currentTab = allDocuments[types.toString()];
    const { documents } = currentTab;

    setTimelineLength(documents.length);

    const data = await formatExamTimeline(documents, subjectToken);
    if (data && Array.isArray(data)
    && data.length) setDocumentsShown(data[0]);
  };

  const loadDocuments = async () => {
    setLoading(true);
    const { types } = timelineFilter;

    let preloadedDocumentsInfo = allDocuments[types.toString()];
    if (!preloadedDocumentsInfo) preloadedDocumentsInfo = {};

    const patientOid = verifyCPF(patientId) ? oids.cpf : oids.cns;

    const {
      retTimeline,
      nextUrlTimeline: nextUrl,
      unavailableReports,
    } = await MHDRequests.getExamFullTimeline({
      patientId,
      organizationId,
      oid: patientOid,
      next: preloadedDocumentsInfo?.nextUrlLink,
      type: types.toString(),
      purposeOfUse,
      subjectId,
      subjectToken,
      subjectRole,
    });

    if (unavailableReports) setIsReportsUnavaiable(unavailableReports);

    if (typeof retTimeline === 'string') {
      setLoading(false);
      return null;
    }

    let allTabDocuments = preloadedDocumentsInfo?.documents
      ? [...preloadedDocumentsInfo.documents] : [];
    if (Array.isArray(retTimeline)) {
      allTabDocuments = [...allTabDocuments,
        ...retTimeline.map((item) => item.resource)];
    }

    const newAllDocuments = { ...allDocuments };
    newAllDocuments[types.toString()] = {
      documents: allTabDocuments,
      nextUrlLink: nextUrl,
    };

    return newAllDocuments;
  };

  useEffect(() => {
    const { types } = timelineFilter;

    function buildDocuments(documents) {
      const newDocuments = documents[types.toString()].documents.sort((a, b) => new Date(a.issued).getTime() - new Date(b.issued).getTime());

      const documentsBuild = {};
      documentsBuild[types.toString()] = {
        documents: newDocuments,
        nextUrlLink: '',
      };

      return documentsBuild;
    }

    async function loadAllDocuments() {
      const allLabDocuments = await loadDocuments();

      const newAllDocuments = buildDocuments(allLabDocuments);

      setAllDocuments(newAllDocuments);
    }

    if (allDocuments[timelineFilter.types.toString()]) {
      getTimelineType({ types: timelineFilter.types }).then(
        () => setLoading(false),
      );
    } else loadAllDocuments();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocuments]);

  return (
    <div className="timelineDrawer-container">
      <div className="timelineDrawer-upperArea">
        {
          loading && (<Loading type="linear" />)
        }
      </div>
      <React.Fragment key={Math.random() * Math.random()}>
        <div className="timelineDrawer-loadMoreScroll">
          <LoadMoreScroll
            length={timelineLength}
            hasNext={!!allDocuments[timelineFilter.types
              .toString()]?.nextUrlLink}
            action={async () => loadDocuments()}
          >
            {!documentsShown.length && !loading
              ? (
                <div style={{ maxWidth: '40vw', marginLeft: '25%' }}>
                  <NoReports label={isReportsUnavailable ? 'PatientSummary-unavailableExamsReports' : 'PatientSummary-noExamsReports'} />
                </div>
              )
              : (
                <Timeline
                  data={documentsShown}
                  selectReport={selectReport}
                  examPdfCallback={examPdfCallback}
                  isPdf
                />
              )}
          </LoadMoreScroll>
        </div>
      </React.Fragment>
    </div>
  );
}

TimelineExamViwer.propTypes = {
  organizationId: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
  selectReport: PropTypes.func.isRequired,
  subjectId: PropTypes.string.isRequired,
  purposeOfUse: PropTypes.string,
  subjectToken: PropTypes.string,
  subjectRole: PropTypes.string,
};

TimelineExamViwer.defaultProps = {
  purposeOfUse: 'Atendimento',
  subjectToken: '',
  subjectRole: '',
};
