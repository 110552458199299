import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { useTranslation } from 'react-i18next';

function AllergyTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');
  const headers = [t('myRegisters-patientSummary-healthSummary-tab-column-level'), t('myRegisters-patientSummary-healthSummary-tab-column-description'), t('myRegisters-patientSummary-healthSummary-tab-column-date')];

  const rows = documents.map((document) => ({
    id: document.id,
    description: document.code?.text,
    level: document.criticality,
    source: document.meta.tag ? document.meta.tag[0].code : document.meta.source,
    date: formatDateDisplay(document.recordedDate),
  }));

  return (
    <Table
      size="small"
      aria-label="enhanced table"
    >
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <TableCell
              key={i}
            >
              <h5>
                {header}
              </h5>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((document) => {
            let level = '';
            if(document.level === 'low') {
              level = 'baixo';
            }
            else if (document.level === 'high') {
              level = 'alto';
            }
            else if (document.level === 'unable-to-assess') {
              // unable-to-assess
              level = 'indeterminado';
            }
            return (
            <Tooltip
              key={document.id}
              placement="top"
              arrow
              title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
            >
              <TableRow
                hover
                onClick={() => selectReport(document.source)}
              >
                <TableCell padding="checkbox">{level}</TableCell>
                <TableCell>{document.description}</TableCell>
                <TableCell padding="checkbox">{document.date}</TableCell>
              </TableRow>
            </Tooltip>
          )})
        }
      </TableBody>
    </Table>
  );
}

AllergyTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { AllergyTabContent };
